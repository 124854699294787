#admin{
    .visitPurpose{
        margin-bottom: 30px;
    }

    .notes_list:hover{cursor: pointer;}
    
    .table-hover > tbody > tr:hover {background-color: none!important;}
    table thead tr{border-bottom: solid 10px $bootstrapPanelGrey}
    table thead th{text-transform: uppercase;font-weight: bold;color: black}
    table {
        background-color: white;
        .last-visit-date{
            position: relative;
            margin-bottom: 20px;
            .last-visit-wrap{
                position: absolute;
                .last-visit-day{
                    width: 29px;
                    cursor: pointer;
                }
            }
            span{
                color:$textBlue;
                font-weight: bold;
                font-size: 21px;
                padding-left: 40px;
            }
        }
        .last-visit-button-wrap{
            .last-visit-button{
                padding: 5px 5px!important;
                width: 100%;
                height: 35px;
                font-size: 16px;
                background-color: #006b75;
                margin-bottom: 10px;
                -webkit-border-radius: 6px; 
                -moz-border-radius: 6px; 
                border-radius: 6px; 
            }
            .last-visit-button:hover{
                background-color: $vp_button_hover;
            }
        }

        .review-status-wrap{
            float: left;
            margin-right: 10px;
            .review-status{
                width: 21px;
            }
        }

        .temp-password:hover{
            cursor: pointer;
        }

        .add-new-form-wrap{
            padding: 4px 0;
            display: flex;
            background-color: $lightGrey;
            margin-bottom: 10px;
            .add-new-form-label{
                color: black;
                text-align: center;
                font-weight: bold;
                font-size: 17px;
                display: flex;
                margin-right: 3px;
                //width: 20%;
                .glyphicon{
                    top: 6px;
                    position: absolute;
                    right: -3px;
                }
            }
            .add-new-form-select{
                //width: 80%;
                margin-right: 3px;
                margin-left: 3px;
            }
            .add-new-form-button{
                text-align: right;
                float: right;
                height: 34px;
                margin-left: 3px;
                //width: 20%;
                button{width: 100%}
            }
        }
        .pushForm{
            max-height: 500px;
            overflow-y: auto; 
            overflow-x:hidden;
        }
    }

    .patient-files{
        float:  right;
        max-height: 400px;
        padding-top: 10px;
        background-color: $lightGrey;
        overflow-y: auto;
        overflow-x:hidden;
        max-height: auto;
        .header{
            position: relative;
            background-color: white;
            display: inline-flex;
            width: 100%;
            .header-left-img{
                img{
                    width: 40px;
                }
            }

            .header-header-text{
                width: 100%;
                text-align: center;
                color: $textBlue;
                font-weight: bold;
                font-size: 18px;
                padding-top: 10px;
            }

            .header-right-img{
                width: 56px;
                padding-left: 6px;
                padding-top: 6px;
                background-color: $footerBackground;
                cursor: pointer;
                img{
                    width: 28px;
                }
                .OpenFileUpload{
                    background-image: url("../img/listing_add_files.svg");
                    width: 28px;
                    height: 28px;
                    background-repeat: no-repeat;
                    margin-bottom: 0!important;
                    cursor: pointer;
                }
            }
        }
        .files-listing{
            margin-top: 15px;
            .files-listing-row{
                padding-bottom: 5px;
                margin-right: 2px;
                .ellipsis{
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .file-download,.file-delete{
                    width: 24px;
                    cursor: pointer;
                }
                .file-icon{
                    width: 22px;
                }
            }

            .files-listing-row:hover{background-color: white;}
        }
        .overlay{
            z-index: 100;
            position: absolute;
            background-color: black;
            opacity: 0.5;
            width: 100%;
            height: 100%;
        }
    }

@media (max-width: 991px){
    #patients_list{
        .patient-files{
            position: relative!important;
            max-height: 400px!important;
        }
    }
}

    table tr{color: black; font-size: 15px;border-bottom: solid 5px $bootstrapPanelGrey}
    @media (max-width: 991px){
        table tr{font-size: 13px;}
    }

    .table>tbody>tr>td{vertical-align: middle!important;}
    .table-bullet-wrap{
        position: relative;
        width: 40px;
        height: 35px;
        .table-row-bullet{
            position: absolute;
            background-color: $footerBackground;
            height: 34px;
            width: inherit;
            color: white;
            text-align: center;
            padding-top: 6px;
        }
        .table-row-triangle{
            position: absolute;
            top: 6px;
            right: -15px;
        }
        .glyphicon-triangle-bottom{
            top: 26px!important;
            left: 10px!important;
        }
    }
    .table-bullet-wrap:hover{
        cursor: pointer;
    }
    #patients_list{
        .glyphicon{font-size: 22px;color: black}
        .partial_form_button{display: block; width: 100%}
        .partial_form_status{height: 54px;}
    }

    .details-wrap{
        .detail-icon:hover{
            cursor: pointer;
        }
    }
}