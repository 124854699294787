 //Variables 
@import "resources/assets/sass/global/variables";

//Node modules
/* @import "node_modules/font-awesome/scss/font-awesome"; */
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

//Globals
@import "resources/assets/sass/global/global"; 
@import "resources/assets/sass/global/mixins"; 
@import "resources/assets/sass/global/forms_tablet"; 

//Common partials 
@import "resources/assets/sass/partials/common/checkboxes";
@import "resources/assets/sass/partials/common/header";
@import "resources/assets/sass/partials/common/footer";
@import "resources/assets/sass/partials/common/sections";
@import "resources/assets/sass/partials/common/tooltip";

//Partials
@import "resources/assets/sass/partials/patients_list";
@import "resources/assets/sass/partials/register_and_patient_edit";
@import "resources/assets/sass/partials/search";
@import "resources/assets/sass/partials/login";
@import "resources/assets/sass/partials/consent";
@import "resources/assets/sass/partials/prescription_pdf";
@import "resources/assets/sass/partials/forms_css_fix";
@import "resources/assets/sass/partials/signature";

/* covid registration new */
#company-covid-pcr-registration {
    .panel-body {
        padding-right: 60px;
        padding-left: 60px;
    }

    .breadcrumb {
        margin-bottom: 0;
    }
    .custom_breadcrumb {
        color:white;
        font-weight: bold;
        background-color: #006b75;
    }
}
