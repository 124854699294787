.tooltip.top .tooltip-inner{
    background-color:$footerBackground;
}

.tooltip > .tooltip-arrow {border-bottom: #f00;}

.tooltip-wrap{
    text-align: left;
}

.tooltip-text{
    padding-top: 7px;padding-left: 35px;
}