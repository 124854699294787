
/*Search Section */
#search-section{
    position: relative;
    width: 100%;
    height: auto;
    background-color: $footerBackground;
    margin-bottom: 30px;
    .search-section-header{
        text-align: center;
        color: white;
        margin: 15px;
        padding: 5px;
        border-bottom: solid 2px $lightGrey;
    }
    #search-floating{
        position: absolute;
        img{
            width: 55%;
            margin: 20px;
        }
    }
    .left-addon input  { padding-left:  62px; }
    .right-addon input { padding-right: 62px; }
    .search_button_wrap{
        padding-bottom: 20px;
        .search_button{
            position: relative;
            font-weight: bold;
            width: 180px;
        }
        .submit_icon{
            width: 35px;
            position: absolute;
            top: 4px;
            right: 0;
        }
    }
}
/* End Search Section */

/* For input Icons */
.inner-addon { 
    position: relative; 
    img{ 
        padding: 0!important;
        background-color: #b2b2b2;
        border-left: solid 2px grey;
        width: 36px;
    }
}

.inner-addon .icon {
  position: absolute;
  padding: 10px;
  pointer-events: none;
}

.left-addon .icon  { left:  0px;}
.right-addon .icon { right: 0px;}

.left-addon input  { padding-left:  30px; }
.right-addon input { padding-right: 30px; }

.inputs-section{
    .inputs-padding{
        padding: 0 40px;
    }
    .inputs-top{
        input,select{
            font-size: 17px;
            font-weight: bold;
        }
    }
    #date_month,#date_day{
        width: 24%;
        display: inline!important;
    }
    #date_year{
        width: 49%;
        display: inline!important;
    }
}
/*END For input Icons */