#login-view{
    .register-btn{
        width: 300px;
        margin-right: auto;
        margin-right: auto;
        margin-left: auto;
        margin-top: 40px;
    }

    .scheduler-border{
        border: none !important; 
    }

    .register-title{
        background-color: #006b75;
   		margin: 4px 3px 0px 60px;
	    padding: 2px 0px 2px 10px;
	    max-width: 1000px;
	    color: white;
	}

    .register-title img{
        padding: 7px 5px 7px 22px;
    }

    .register-section{
        background-color: white;
        padding: 15px 0px 0px 91px;
        height: 64px;
        color: black;
        margin: 0px 0px 21px 84px;
        max-width: 445px;
        border-radius: 10px;
    }

    .register-section img{
        padding-right: 10px;
    }

    .section-right > div{
        margin-left : 20px;
    }

    .sections{float: right;}

    .section-left{
        border-right: 2px solid white;
    }

    .legend-left{
         margin: 0px 0px 21px 8px !important;
    }

    .register-btn{
        margin-top: 10px !important;
    }
    @media (max-width: 1200px){
        .register-section{
            padding: 15px 0px 0px 45px !important; 
            margin: 0px 0px 21px 75px !important;
            max-width: 357px !important;      
        }

        .legend-left{
            margin: 0px 0px 21px 11px !important;
        }
    }

    @media (max-width: 990px){
        .register-section{
            margin: -1px 0px 21px 7px !important;
            max-width: 670px !important    
        }

        .section-left{
            border-right: none; 
        }
        .legend-left{
            margin: 0px 0px 21px 11px !important;
        }

        .section-right > div{
        	margin-left : 0px;
    	}

    	.register-title {
    		margin: 9px 0px 7px 0px;
		}
    }

    .form-group{
        padding-bottom: 7px!important;
    }
} 

#login-default{
    .login-default-wrapper{
        min-height: 500px;
        background-color: $lightGrey;
        margin-bottom: 25px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        border: solid 1px #dedede;
        .header-message{
            margin-top: 20px;
            font-weight: bold;
            font-size: 45px;
        }
        .header-message-line{
            height: 4px;
            width: 250px;
            background-color: $footerBackground;
            margin: auto;
            margin-top: 44px;
            margin-bottom: 44px;
        }
        .login-button-image-wrap{
            .img-button{
                height: 70px;
                width: 210px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                a{  
                    color: white;
                }
                a:hover{  
                    text-decoration: none;
                }
            }
        }
    }
}

#forgot-password-view{
	.subtitle{
		font-size: 20px;
    	margin-top: -10px;
    	padding: 0px 3px 16px 10px;
	}
}

@media (min-width: $screen-md){
    #login-view{
        .auth-fields-wrap{
            padding-left: 80px;
        }
    }
}


@media (min-width: $screen-sm) and (max-width: $screen-md){
    #login-default{
        .login-default-wrapper{
            .login-button-image-wrap{
                .login-button-image{
                    width: 320px;
                }
            }
        }
    }
}

@media (min-width: $screen-xs) and (max-width: $screen-xs-max){
    #login-default{
        .login-default-wrapper{
            .login-button-image-wrap{
                margin-bottom: 15px;
                .login-button-image{
                    width: 70%;
                }
            }
        }
    }
}