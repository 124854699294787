
/* Header */
.navbar-background{display: none;}
@media (min-width: 768px){
    #app-layout{
        .navbar{
            #app-navbar-collapse{
                .navbar-background{
                    display: block;
                    position: absolute;
                    width: 100%;
                    color: white;
                    img{
                        width: 100%;
                        height: 70px;
                    }
                }
                a{
                    color: white!important;
                }
                .dropdown-menu a{color: black!important}
                .navbar-default .navbar-nav>li>a:focus, .navbar-default .navbar-nav>li>a:hover{
                    color: white!important;
                }
                .notif{
                    position: absolute;
                    top: 0;
                    right: 0;
                    background-color: red;
                    border-radius: 50%;
                    padding: 2px;
                    font-size: 16px;
                    font-weight: bold;
                }
            }
        }
    }
}
