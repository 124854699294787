.prescription-wrapper{
    .prescription-top-corner-section{
        background-color: $footerBackground;
        height: 60px;
        width: 300px;
        position: absolute;
        top: 3px;
        left: 13px;
        -webkit-border-top-right-radius: 30px;
        -webkit-border-bottom-right-radius: 30px;
        -moz-border-radius-topright: 30px;
        -moz-border-radius-bottomright: 30px;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
    }
    height: 1140px;
    padding: 10px;
    border:solid 3px $footerBackground;
    .prescription-footer{
        width: 90%;
        background-color: white;
        margin: auto;
        height: 50px;
        position: relative;
        bottom: -35px;
        .left-half{
            .top-bar{margin-bottom: 4px;background-color: $footerBackground;height:22px;
                -webkit-border-top-left-radius: 10px;
                -moz-border-radius-topleft: 10px;
                border-top-left-radius: 10px;
            }
            .bottom-bar{background-color: $partial_add_green;height: 22px;
                -webkit-border-bottom-left-radius: 10px;
                -moz-border-radius-bottomleft: 10px;
                border-bottom-left-radius: 10px;
            }
        }
        .right-half{}
    }
}

.prescFixPdfBody{
    height: 83%;
}