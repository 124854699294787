 .navbar-forms-tablet{
    background-color: $footerBackground;
    ul li a{
        color: white !important;
    }
 }

 a:hover {cursor: pointer;}
 .common-green-bg{background-color: $footerBackground!important}
 .color-white{color:white;}
 .btn-square{font-size: 17px;font-weight: bold;padding: 25px;}
 .btn-rectangle{font-size: 17px;font-weight: bold;padding: 10px 45px}
 .fa-check {color: #4CAF50;padding-right:5px;}
 .text-align-center {text-align: center;}

.forms_tablet_listing_panel {
    border: none;
    .forms_tablet_listing {
        font-size: 16px; 
        font-weight: bold;
        .list_top {
            a {text-decoration: underline!important;}
            padding-bottom: 15px;
        }
        .list_row{
            padding: 5px 0;
            .fa-check{
                color: $partial_add_green;
                font-size: 18px;
                padding-right: 5px;
            }
        }
        .border_bottom {
            border-bottom: 1px solid grey;
            padding: 17px 0;
            .tablet-pin-wrapper{
                text-align: center!important;
                padding: 160px 0;
                margin: auto;
            }
        }
    }
}

.patient-name-title {
    text-align: center;
    font-size: 28px;
    color: blue;
}

.form-name-title {
    font-size: 23px;
    font-weight: bold;
    padding-bottom: 20px;
}

#open-forms-wrap {
    .slide-button{
        color:blue;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        position: fixed;
        bottom: 200px;
    }
}

#forms-completed-wrap {
    .fa-check-completed {
        font-size: 230px;
        padding: 35px 0;
    }

    .forms-completed-message{
        font-size: 21px;
        padding: 15px 0;
    }
}