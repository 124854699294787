// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
/* Colors */
#register-form,.admin-register-form{
    #register-form-desktop{
        #form-content{
            .date-birth-section,.state-section{
                .date-birth-group,.state-group{
                    display: flex;
                    input{width: 45px!important;}
                    span{margin-top: 5px;padding: 0 5px;font-weight: bold;}
                }
            }
            .main-section-heading{
                h2{text-align: center;color: blue;}
            }
            .divider{height: 7px;width: 100%;background-color: $footerBackground;float:left;margin-bottom: 10px}
            .row-divider{
                padding-bottom: 30px;
                .patient-initials{
                    width: 120px;
                    float: right;
                }
            }
        }
    }
    #med_release-section-desktop{
        .med_contact{
          font-size: 17px;
          text-align: center;
          font-weight: bolder;
        }

        .p{
            margin-bottom: 0;
        }

        .ol{
            list-style-position:outside;
        }

        .med_rel_ol li{
            padding-left: 10px;
            font-weight: bold;
            margin-top: 10px;

        }

        .med_rel_ol span{
              font-weight: normal;
        }

        .authorization{
            text-align: left;
            font-weight: normal;
            padding-left: 40px
        }

        #aut_exp_inpt{
            width: 77.5%;
            float: right;
            margin-right: 16px;
        }

        .auth_expires{
            font-weight: normal;
            padding-left: 13px;
        }

    }


    #rec-tuberculosis-section-desktop{
        .tub_desc{
            font-size: 12px;
            margin-top: 15px;
        }
    }

    #immigration-med-exam-section-desktop{
        .additional_service{
            ul{
                list-style-type: none;
                padding-left: 0px;
            }
        }
    }

    #immigration-assessment-exam-section-desktop{
        .additional_service{
            ul{
                list-style-type: none;
                padding-left: 0px;
            }
        }
        
        h3{
            font-size: 25px;
            padding: 10px 10px 21px 0px;
        }
    }

    
    .flex{
        @media (min-width: 768px){
            display: -webkit-flex; /* Safari */
            display: flex;
            webkit-justify-content: space-between; /* Safari */
            justify-content:         space-between;
            -webkit-align-items: center; /* Safari */
            align-items:         center;
        }
    }
}

@media (max-width: 1199px){
    #register-form{
        #register-form-desktop{
            .main-section-heading{
                .main-section-heading-text{
                    span{
                        top: 10px;
                        font-size: 18px;
                    }
                }
            }
        }
    }
}
@media (max-width: 768px){
    #register-form{
        #register-form-desktop{
            .panel-heading-section{
                .panel-heading-section-text{
                    span{
                        font-size: 20px;
                    }
                }
            }
        }
    }
}

#i693-section-desktop{
    ol{
        list-style-position:outside;
    }

    .med_rel_ol li{
        padding-left: 10px;
        font-weight: bold;
        margin-top: 10px;
    }

    .med_rel_ol span, .med_rel_ol p{
          font-weight: normal;
    }

    .normal{
        font-weight: normal !important;
    }

    .checkDiv{
        position: relative;
        float: left;
        margin-right: 5px;
    }

    .fLeft{
        position: relative;
        float: left;
    }

    .marginR10{
        margin-right: 10px;
    }

    .marginR20{
        margin-right: 1000000000000px;
    }

    .marginL20{
        margin-left: 20px;
    }

    .noPadding{
        padding: 0;
    }

     .white{
        background-color: white;
    }

    .gray{
        background-color: #f7f7f7;
    }

    .green{
        color: #1d7f8a;
    }

    .infoBox{
        padding: 30px 5px 30px 5px;
    }

    .infoBoxSmal{
        padding: 0px 5px 15px 5px;
        margin-top: -15px;
    }

    .colLogo{
        padding: 5px 0px 0px 16px !important;
    }
}