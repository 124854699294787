#i693-section-desktop{
    .legend-section{
        width: 700px!important;
    }
}
@media (max-width: 991px){
    #i693-section-desktop{
        .form-top-margin{
            margin-top: 18px;
        }
        .legend-section{
            width: 507px!important;
            font-size: 14px;
        }
    }
}