/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 100;
  src: local('Lato Hairline'), local('Lato-Hairline'), url(https://fonts.gstatic.com/s/lato/v11/eFRpvGLEW31oiexbYNx7Y_esZW2xOQ-xsNqO47m55DA.woff2) format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 100;
  src: local('Lato Hairline'), local('Lato-Hairline'), url(https://fonts.gstatic.com/s/lato/v11/GtRkRNTnri0g82CjKnEB0Q.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: local('Lato Light'), local('Lato-Light'), url(https://fonts.gstatic.com/s/lato/v11/dPJ5r9gl3kK6ijoeP1IRsvY6323mHUZFJMgTvxaG2iE.woff2) format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: local('Lato Light'), local('Lato-Light'), url(https://fonts.gstatic.com/s/lato/v11/EsvMC5un3kjyUhB9ZEPPwg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v11/UyBMtLsHKBKXelqf4x7VRQ.woff2) format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v11/1YwB1sO8YE1Lyjf12WNiUA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: local('Lato Bold'), local('Lato-Bold'), url(https://fonts.gstatic.com/s/lato/v11/ObQr5XYcoH0WBoUxiaYK3_Y6323mHUZFJMgTvxaG2iE.woff2) format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: local('Lato Bold'), local('Lato-Bold'), url(https://fonts.gstatic.com/s/lato/v11/H2DMvhDLycM56KNuAtbJYA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@media print {
  a[href]:after {
    content: none !important;
  }
  #dataRxForm,#dashboard-style,.widget-submit,.checkbox,.bottom-border,.hide-print{
    display: none;
  }
  #homepage{
    padding-bottom: 0px!important;
    padding-top: 0px!important;
  }
 
}

html,body { font-family: $defaultfont; }
body{
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    moz-osx-font-smoothing: grayscale;
}

.padding-right0{
    padding-right: 0!important;
}
.padding-left0{ 
    padding-left: 0!important;
}
.padding-top0{
    padding-top: 0!important;
}
.bold{ font-weight:bold;}
.alignLeft{text-align:left !important;}
.alignCenter{text-align:center;}
.alignRight{text-align:right;}
.blue{color:$textBlue;}
.padding-bottom10{
    padding-bottom: 10px!important;
}
.padding-top10{
    padding-top: 10px!important;
}
.padding-top14{
    padding-top: 14px;
}

.margin-l30{
    margin-left: 30px !important;
}

.floatLeft{
    positon: relative !important;
    float: left !important;
}

.postTitle{
    font-size: 17px;
    margin: 10px 0px 10px 0px;
}

.postSubTitle{
    font-size: 17px;
}

.button-missing-label{
    margin-top: 26px;
}
.underline{
    border-bottom: 1px solid black;
    padding-bottom: 3px;
}

.pdfWidthFix{
    width: 150px !important;
}

.none{
  display: none;
}

.cursor-pointer{
    cursor: pointer;
}

/* Overrides */
.nav-less-padding-sides a{
  padding-right:10px!important;
  padding-left:10px!important;
}

.container{
  min-height: 450px!important;
}

@media (min-width: 768px){
    .navbar-right {
        float: right!important;
        margin-right: 0!important;
    }
}
.panel-body{
    background-color: $lightGrey;
}
.form-control{border-radius: 0;}
.btn{border-radius: 0;}
.navbar-default .navbar-nav>li>a {
    color: black;
    font-size: 17px;
}
.navbar-default .navbar-nav>li>a:focus, .navbar-default .navbar-nav>li>a:hover{
    color: black;
}
.navbar-nav{padding-top: 7px!important;}
.pagination a{color: black!important}
.pagination>.active>span,{background-color: $footerBackground!important;}
input[type=checkbox]:focus,input[type=radio]:focus{
    outline:none;
    outline: none;
    outline-offset: none;
}
.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:focus, .navbar-default .navbar-nav>.open>a:hover{
    background-color: rgba(0,107,116,0.5);
    color: white;
}
.row-bg-default{background-color: white;}
.divlisting{min-height: 40px;}
.divlisting:hover{min-height: 40px;background-color: $hover}
.divlistingcell{margin-top: 10px;}
.btn-info{
  transition: all 400ms ease;
}
.btn-info:hover{
  box-shadow: inset 350px 0 0 0 rgba(0,0,0,0.3);
  -webkit-box-shadow: inset 0 100px 0 0 rgba(0,0,0,0.3);
  -moz-box-shadow: 0 0 5px rgba(0,0,0,0.3);
}

/* End Overrides */
 
.header_logo_img{width: 85px;}
.modalXClose{position:relative;right:0px;top:-2px;color:black;font-size: 30px;cursor: pointer;}
.paddingTop7{padding-top: 7px;}

.hide{
    display: none;
}
.show{
    display: block;
}
.padding-panel-rows-text{
    padding: 6px 12px;
}
.redError{
  color: $red !important;
}
.redErrorBackground{
  background-color: $red !important;
}
.glyphiconArrowCounter{color: $footerBackground!important}
.rowHeightFixForSignature{
  height: 130px;
}
.rowHeightFixForSignatureL{
  height: 130px;
}
.inline-block{
  display: inline-block;
}
.pdf-signature-height-fix90{
  height: 90px;
}
.pdf-date-padding-top{
  padding-top: 16px;
}
.input-text-padding-top{
  padding-top: 7px;
}
.height-fix{
  padding-top: 14px;
}

.checkboxFix{
  padding-left: 30px !important;
}

.fontWeightNormal{
  font-weight: normal!important;
}

.button-animation{
    * {
        -webkit-transition-property: all;
        -webkit-transition-duration: .2s;
        -moz-transition-timing-function: cubic-bezier(100,50,21,6);
        -moz-transition-property: all;
        -moz-transition-duration: .2s;
        -moz-transition-timing-function: cubic-bezier(100,50,21,6);
    }

    .img-button {
      position: relative;
      display: inline-block;
      width: 210px;
      height: 70px;
      font-size: 18px;
      line-height: 25px;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
      cursor: pointer;
      overflow: hidden;
      padding-top: 11px;
    }

    .img-button svg {
        position: absolute;
        top: 0; left: 0;
    }

    .img-button svg rect {
        fill: none;
        stroke: #fff;
        stroke-width: 5;
        stroke-dasharray: 350, 0;
      
        @include transition(all 900ms);
    }

    .img-button:hover svg rect {
        stroke-width: 5;
        stroke-dasharray: 10, 404;
        stroke-dashoffset: 33;
    }
}


/* Scrollbar */
html {
    /* overflow: auto; */
}
body {
    overflow-y: auto;
    overflow-x: hidden;
}

::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    -webkit-border-radius: 10px;
    border-radius: 10px;
}
 

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: $footerBackground; 
    opacity: 0.8;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}
::-webkit-scrollbar-thumb:window-inactive {
    background: $footerBackground; 
    opacity: 0.4;
}
/* End Scrollbar */

.ui-button{height: 30px;}
.custom-combobox-input{height: 31px;}
.patient-initials {
    text-transform: uppercase;
}

.hr{
    background-color: grey;
    margin-bottom: 0!important;
    margin-top: 0!important;
    margin-left: 35px!important;
    margin-right: 0!important;
}

/* tablet fixing */
@media (max-width: 991px){
  body {
    font-size: 13px!important;
  }
}
/* End tablet fixing */

.spanAnchor {
    cursor:pointer;
    color:#46469c;
    text-decoration:underline;
}