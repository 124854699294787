.main-section-heading{
    padding-top: 60px;
    padding-bottom: 30px;
    .main-section-heading-img{
        margin-right: 7px;
        float: left;
        width: 90px;
        background-color: white;
    }
    .main-section-heading-text{
        background-color: $darkGrey;
        width: 100%;
        height: 70px;
        span{
            position: relative;
            top: -1px;
            padding-left: 5px;
            font-weight: bold;
            font-size: 24px;
            color: white;
        }
    }
}
.fieldset-section{
    padding-top: 20px;
    fieldset.scheduler-border {
        background-color: white;
        border: 1px groove #ccc !important;
        padding: 0 1.4em 1.4em 1.4em !important;
        margin: 0 0 1.5em 0 !important;
        -webkit-box-shadow:  0px 0px 0px 0px #000;
                box-shadow:  0px 0px 0px 0px #000;
    }

    legend.scheduler-border {
        font-size: 1.2em !important;
        font-weight: bold !important;
        text-align: left !important;
        width:auto;
        padding:0 10px;
        border-bottom:none;
        .legend-section{
            background-color: $footerBackground;
            padding-right: 7px;
            color: white;
            width: 345px;
            img{
                width: 45px;
                margin-right: 10px;
                margin-left: 5px;
                /* image-rendering: -webkit-optimize-contrast; */
            }
        }
    }
}
.panel-heading-section{
    height: inherit;
    float: left;
    width: 100%;
    .heading-icon{
        background-color: $footerBackground;
        height: 70px;
        min-width: 91px;
        /* width: 100px;
        float: left; */
        img{
            width: 85px;
            padding-left: 8px;
        }
    }
    .panel-heading-section-text{
        background-color: white;
        position: relative;
        height: 70px;
        span{
            position: absolute;
            top: 16px;
            padding-left: 5px;
            font-weight: bold;
            font-size: 23px;
        }
    }
}

.submit-form{
    background-color: $lightBlue;
    border-radius: 0;
    min-width: 220px;
    height: 60px;
    font-size: 20px;
}

.upper-button{
    padding-top: 15px;
}

.partial_add_blue{
    background-color: $partial_add_blue;
}
.partial_add_red{
    background-color: $partial_add_red;
}
.partial_add_green{
    background-color: $partial_add_green;
}