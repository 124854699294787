// Extra small screen / phone
$screen-xs:                  1px;
$screen-xs-min:              $screen-xs;
$screen-phone:               $screen-xs-min;

// Small screen / tablet
// Note: Deprecated $screen-sm and $screen-tablet as of v3.0.1
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm;
$screen-tablet:              $screen-sm-min;

// Medium screen / desktop
// Note: Deprecated $screen-md and $screen-desktop as of v3.0.1
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
// Note: Deprecated $screen-lg and $screen-lg-desktop as of v3.0.1
$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg;
$screen-lg-desktop:          $screen-lg-min;

$screen-xl:                  1360px !default;
$screen-xl-min:              $screen-xl;
$screen-xl-desktop:          $screen-xl-min;

// So media queries don't overlap when required, provide a maximum

$screen-tn-less:             479px !default;
$screen-xs-less:             ($screen-xs-min - 1) !default;
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;
$screen-lg-max:              ($screen-xl-min - 1) !default;
$screen-xl-max:              1599px !default;


//override bootstrap border-radius
$border-radius-none:        0px !default;

// Grid system
// --------------------------------------------------

// Number of columns in the grid system
$grid-columns:              12;

$grid-min-width:            768px;
$tablet-md-width:           850px;

// Padding, to be divided by two and applied to the left and right of all columns
$grid-gutter-width:         20px;

/*dimensions*/
$container-max-width: 1366px;
$container-width: 1366px;

/* fonts */
$defaultfont: 'Lato', sans-serif;

/* colors */
$pagedarkgray: #f2f2f2;
$fieldsdarkgray: #a9a9a9;
$footer: #566270;
$lightyellow: #f9e286;
$darkyellow: #f6d653;
$header: #556270;
$green: #97bb03;
$black: #000000;
$white: #ffffff;
$fieldgreen: #799701;
$red: #ff0000;
$color-1: #6c7a8a;
$color-2: #808e9e;
$color-3: #f7d653;
$color-4: #fae386;
$color-5: #504e53;
$color-6: #858585;
$color-7: #f5f5f5;
$color-8: #fdf4cd;
$color-9: #e4e4e4;
$color-10: #b7b7b7;
$color-11: #f3f3f3;
$color-12: #d2d2d2;
$color-13: #c9e2fd;
$color-14: #fdf8e0;
$color-15: #17478f;
$color-16: #cacaca;

$modal-lg-alt: 900px !default;
$modal-md-alt: 900px !default;
$modal-sm:     300px !default;

/* Colors */
$footerBackground: #006b75;
$lightGrey: #f6f4f5;
$darkGrey: #474747;
$lightBlue: #03a3af;
$bootstrapPanelGrey: #f6f4f5;
$textBlue: #0088CC;
$hover: #b6b6b6;

$partial_add_blue: #0288D1;
$partial_add_red: #EF5350;
$partial_add_green: #4CAF50;

$vp_button: #546e7a;
$vp_button_hover: #607d8b;
/* End Colors */


