/* Checkboxes */
@-webkit-keyframes click-wave {
  0% {
    height: 25px;
    width: 25px;
    opacity: 0.35;
    position: relative;
    border: none;
  }
  100% {
    height: 100px;
    width: 100px;
    margin-left: -40px;
    margin-top: -40px;
    opacity: 0;
    border: none;
  }
}
@-moz-keyframes click-wave {
  0% {
    height: 25px;
    width: 25px;
    opacity: 0.35;
    position: relative;
    border: none;
  }
  100% {
    height: 100px;
    width: 100px;
    margin-left: -40px;
    margin-top: -40px;
    opacity: 0;
    border: none;
  }
}
@keyframes click-wave {
  0% {
    height: 25px;
    width: 25px;
    opacity: 0.35;
    position: relative;
    border: none;
  }
  100% {
    height: 100px;
    width: 100px;
    margin-left: -40px;
    margin-top: -40px;
    opacity: 0;
    border: none;
  }
}
.option-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: relative;
  right: 0;
  bottom: 0;
  left: 0;
  height: 25px;
  width: 25px;
  -webkit-transition: all 0.15s ease-out 0s;
  -moz-transition: all 0.15s ease-out 0s;
  transition: all 0.15s ease-out 0s;
  background: #cbd1d8;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin-right: 0.5rem;
  outline: none;
  position: relative;
  z-index: 1000;
}
.option-input:hover {
  background: #9faab7;
}
.option-input:checked {
  background: $lightBlue;
}
.option-input:checked::before {
  height: 25px;
  width: 25px;
  position: absolute;
  content: '\2716';
  display: inline-block;
  font-size: 26.66667px;
  text-align: center;
  line-height: 25px;
  border: none;
}
.option-input:checked::after {
  -webkit-animation: click-wave 0.15s;
  -moz-animation: click-wave 0.15s;
  animation: click-wave 0.15s;
  background: $lightBlue;
  content: '';
  display: block;
  position: relative;
  z-index: 100;
  border:none;
}


/* End Checkboxes */