#consent-pdf-desktop{
    #header-section{
        padding: 10px 0;
    }

    #consent-section{
        .attention{
            text-align: center;
            color:red;
        }
    }

    #vaccines-section{
        padding: 10px 0;
    }

    #prescriptions-section,#other-type-section{
        padding: 0px 0px;
    }

    #other-services-section{
        padding: 0px 0;
    }

    #consent-text,#row-text{
        position: relative;
        .initial{
            position: absolute;
            bottom: 0;
            right: 0;
        }
        .price-disclosure-text{
            font-weight: bold;
            font-size: 12px;
            text-transform: uppercase;
            text-decoration: underline black;
        }
    }
}


/* WEB */
#consent{
    table,select,input{
        font-size: 13px;
    }
}