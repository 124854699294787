/*Signature Section */
.wrapper-signature{
    .main_pencil,.user_initial_select{
        color: black;
        font-size: 25px;
        position: relative;
        left: 230px;
        cursor: pointer;
    }

    .signature_actions{
        position: absolute;
        
        left: 240px;
        width: 230px;
        border: solid 1px #d0d0d0;
        z-index: 999999;
        background-color: white;
        cursor: pointer;

        .select_row{
            background-color: red;
            height: 50px;
        }
    }
}
/*END For Signature Section */