#footer{
    position: relative;
    width: 100%;
    height: 150px;
    background-color: $footerBackground;
    .footer-logo{ 
        padding-top: 30px;
        padding-bottom: 25px;
        width: 70px;
        margin: auto;
    }
    .footer-copyright{
        text-align: center;
        color: white;
    }
}